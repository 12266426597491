<template>
  <div class="auth-wrapper bg-white">
    <b-overlay :show="loading">
      <b-row class="auth-inner m-0">
        <!-- Brand logo-->

        <!-- /Brand logo-->

        <!-- Login-->

        <b-col lg="6" class="d-flex align-items-center auth-bg px-2 p-lg-5">
          <b-col sm="8" md="8" lg="12" class="px-xl-2 mx-auto">
            <br />
            <a href="\" class="">
              <h1 class="brand-text text-primary">{{ appShortName }}</h1>
            </a>
            <br />
            <br />
            <b-card-title
              class="mb-1 font-weight-bold text-bold"
              title-tag="h1"
            >
              Masuk
            </b-card-title>
            <b-card-text class="mb-2">
              Silahkan masukkan email dan kata sandi untuk mengakses lebih
              lanjut fitur dari kami
            </b-card-text>

            <!-- form -->
            <validation-observer ref="loginForm" #default="{ invalid }">
              <b-form class="auth-login-form mt-2" @submit.prevent="login">
                <!-- email -->
                <b-form-group label="Alamat Email" label-for="login-email">
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false : null"
                      name="login-email"
                      placeholder="Masukkan email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Kata Sandi</label>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Masukkan kata sandi"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-link
                    class="float-right"
                    :to="{ name: 'auth-forgot-password' }"
                  >
                    <small>Lupa Kata Sandi?</small>
                  </b-link>
                  <!-- <b-form-checkbox id="remember-me" v-model="status" name="checkbox-1"> Remember Me </b-form-checkbox> -->
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  Masuk
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="mt-2">
              <span>Belum punya akun?</span>
              <b-link :to="{ name: 'auth-register' }">
                <span>&nbsp;Daftar Sekarang</span>
              </b-link>
            </b-card-text>
            <p class="clearfix mb-2" style="margin-top: 100px;">
              <span class="float-md-left d-block d-md-inline-block mt-25">
                © {{ new Date().getFullYear() }} {{ appName }}
                <span class="d-none d-sm-inline-block">
                  . All rights Reserved
                </span>
              </span>
            </p>
          </b-col>
        </b-col>
        <!-- /Login-->

        <!-- Right Text-->
        <b-col
          lg="6"
          class="d-none d-lg-flex align-items-center"
          style="height: 90vh;"
        >
          <div class="card bg-dark text-white">
            <img class="" :src="imgUrl" style="width: 100%;" alt="Background" />
            <div class="card-img-overlay container" style="width: 85%;">
              <!-- <center>
                <h1 class="text-white text-left" style="margin-top: 370px;">
                  <b>
                    Latihan Ujian
                    <br />
                    Untuk Hasil Berkualitas
                  </b>
                </h1>
                <div class="mt-3 mb-2">
                  <b-avatar-group size="32px">
                    <b-avatar
                      class="pull-up"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-5.jpg')
                      "
                    />
                    <b-avatar
                      class="pull-up"
                      variant="primary"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-7.jpg')
                      "
                    />
                    <b-avatar
                      class="pull-up"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-10.jpg')
                      "
                    />
                    <b-avatar
                      class="pull-up"
                      variant="danger"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-8.jpg')
                      "
                    />
                    <b-avatar
                      class="pull-up"
                      :src="
                        require('@/assets/images/portrait/small/avatar-s-20.jpg')
                      "
                    />
                  </b-avatar-group>
                </div>
                <p class="text-left">
                  Alumni yang Sukses Setelah Mengikuti Try Out dari Kami
                </p>
              </center> -->
            </div>
          </div>
          <!-- <div
            class="w-100 d-lg-flex align-items-center justify-content-center px-5"
          >
            <b-img fluid :src="imgUrl" alt="Login V2" />
          </div> -->
        </b-col>
        <!-- /Right Text-->
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BOverlay,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BAvatarGroup,
  BAvatar,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import id from 'vee-validate/dist/locale/id.json'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BAvatarGroup,
    BAvatar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      locale: 'id',
      sideImg: require('@/assets/images/elp/bg-login.png'),
      loading: false,
      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    switchLoc() {
      // switch the locale.
      this.locale = this.locale === 'id' ? 'id' : 'id'
      localize(this.locale, id)

      // re-validate to re-generate the messages.
      // this.$refs.simpleRules.validate();
    },
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          this.loading = true
          const payload = {
            email: this.userEmail,
            password: this.password,
          }
          this.$store
            .dispatch('auth/login', payload)
            .then((res) => {
              this.loading = false
              if (res.data.data.level.nama_level == 'Siswa') {
                this.$router
                  .replace(getHomeRouteForLoggedInUser('Siswa'))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Selamat Datang`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `Kamu berhasil login ${res.data.data.name}`,
                      },
                    })
                  })
              } else {
                localStorage.removeItem('token')
                localStorage.removeItem('userData')
                this.$router.replace('/master-admin').then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Gagal Login`,
                      icon: 'AlertCircleIcon',
                      variant: 'warning',
                      text: `Anda adalah admin ${res.data.data.name}, Silahkan login disini!!`,
                    },
                  })
                })
              }
            })
            .catch((error) => {
              this.loading = false
              console.error('error', error)
              this.$refs.loginForm.setErrors(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error `,
                  icon: 'XIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
          // useJwt
          //   .login({
          //     email: this.userEmail,
          //     password: this.password,
          //   })
          //   .then((response) => {
          //     const { userData } = response.data;
          //     useJwt.setToken(response.data.accessToken);
          //     useJwt.setRefreshToken(response.data.refreshToken);
          //     localStorage.setItem("userData", JSON.stringify(userData));
          //     this.$router.replace(getHomeRouteForLoggedInUser(userData.role)).then(() => {
          //       this.$toast({
          //         component: ToastificationContent,
          //         position: "top-right",
          //         props: {
          //           title: `Welcome ${userData.fullName || userData.username}`,
          //           icon: "CoffeeIcon",
          //           variant: "success",
          //           text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
          //         },
          //       });
          //     });
          //   })
          //   .catch((error) => {
          //     this.$refs.loginForm.setErrors(error.response.data.error);
          //   });
        }
      })
    },
  },
  created() {
    this.switchLoc()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
